import http from "./http";

function getAllMakes() {
  return http.get(`config/make`);
}

function getModelsById(id) {
  return http.get(`config/model/${id}`);
}

export default {
  getAllMakes,
  getModelsById,
};
