import React, { lazy, useEffect } from "react";
import { BrowserRouter, Switch, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { renderRoutes } from "./config/routes";

const Layout = lazy(() => import("./components/layout/Layout"));

function App() {
  const { i18n } = useTranslation();

  return (
    <div
      className={i18n.language === "ar" ? "rtl" : ""}
      dir={i18n.language === "ar" ? "rtl" : "ltr"}
    >
      <BrowserRouter>
        <Switch>
          {renderRoutes.map(([key, route]) => {
            return (
              <route.type
                key={key}
                exact
                render={() => (
                  <Layout>
                    <route.component {...route.props} />
                  </Layout>
                )}
                path={route.path}
              />
            );
          })}
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
