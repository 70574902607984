import i18n from "i18next";
import Backend from "i18next-xhr-backend";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { DEFAULT_LANG } from "../common/constants";

const langDetectorOptions = {
  // order and from where user language should be detected
  order: ["localStorage"],

  // keys or params to lookup language from
  lookupLocalStorage: "language",

  // cache user language on
  caches: ["localStorage"],
};

i18n
  .use(LanguageDetector)
  .use(Backend)
  .use(initReactI18next)
  .init({
    backend: {
      /* translation file path */
      loadPath: "/assets/translations/{{lng}}.json",
    },
    detection: langDetectorOptions,
    fallbackLng: DEFAULT_LANG,
    debug: false,
    keySeparator: false,
    interpolation: {
      escapeValue: false,
      formatSeparator: ",",
    },
    react: {
      wait: true,
    },
  });

export default i18n;
