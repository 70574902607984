import { loginCache } from "../cache";

const initialState = {
  token: null,
  user: {},
  vendor: null,
  customer: null,
  isLoggedIn: false,
};

//actions
const LOGIN = "LOGIN";
const LOGOUT = "LOGOUT";
const UPDATE_USER_DETAILS = "UPDATE_USER_DETAILS"

//action creators
export const actionLogin = (user, token, vendor, customer) => {
  return { type: LOGIN, payload: { user, token, vendor, customer } };
};

export const actionLogout = (token) => {
  return { type: LOGOUT, payload: token };
};

export const actionUpdateUserDetails = (user) => {
  return { type: UPDATE_USER_DETAILS, payload: user }
}

const userReducer = (state = { ...initialState }, action) => {
  let newstate = { ...state };

  if (action.type === LOGIN) {
    newstate.isLoggedIn = true;

    const { user, token, vendor, customer } = action.payload;
    [newstate.token, newstate.user, newstate.vendor, newstate.customer] = [token, user, vendor, customer];
    loginCache.save(user, token, vendor, customer)

  } else if (action.type === LOGOUT) {
    loginCache.clear();
    newstate = { ...initialState };

  } else if (action.type === UPDATE_USER_DETAILS) {
    newstate.user = action.payload;
    loginCache.save(newstate.user, newstate.token, newstate.vendor, newstate.customer)
  }

  return newstate;
};

export default userReducer;
