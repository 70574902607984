//KEYS
const TOKEN = 'token';
const USER = 'user';
const VENDOR = "vendor";
const CUSTOMER = "customer"

class Storage {
    setItem(key, value) {
        localStorage.setItem(key, JSON.stringify(value));
    }

    getItem(key) {
        const item = localStorage.getItem(key);
        return item ? JSON.parse(item) : item;
    }

    remove(key) {
        localStorage.removeItem(key);
    }
}

class LoginCache {
    _keys = [USER, TOKEN, VENDOR, CUSTOMER]
    storage = new Storage();

    save(user, token, vendor, customer) {
        this.storage.setItem(USER, user);
        this.storage.setItem(TOKEN, token);
        this.storage.setItem(VENDOR, vendor);
        this.storage.setItem(CUSTOMER, customer);
    }

    fetch() {
        return this._keys.map(key => this.storage.getItem(key))
    }

    fetchToken() {
        return this.storage.getItem(TOKEN)
    }

    clear() {
        this._keys.forEach(key => this.storage.remove(key))
    }
}

export const loginCache = new LoginCache();