import { lazy } from "react";
import { Route } from "react-router-dom";
import PrivateRoute from "./PrivateRoute.component";
import ProtectedRoute from "./ProtectedRoute";

export const routes = {
  home: {
    name: "Home",
    path: "/",
    component: lazy(() => import("../modules/homepage/HomePage")),
    type: Route,
    routeType: "customer",
  },
  login: {
    name: "Login",
    path: "/login",
    component: lazy(() => import("../modules/login/Login")),
    type: ProtectedRoute,
    routeType: "customer",
  },
  signup: {
    name: "Sign Up",
    path: "/register",
    component: lazy(() => import("../modules/register/Register")),
    type: ProtectedRoute,
    routeType: "customer",
  },
  aboutUs: {
    name: "About Us",
    path: "/about-us",
    component: lazy(() => import("../modules/aboutus/AboutUs")),
    type: Route,
    routeType: "customer",
  },
  order: {
    name: "Order",
    path: "/order/:id",
    component: lazy(() => import("../modules/orderPage/OrderPage")),
    type: Route,
    routeType: "customer",
  },
  emailVerify: {
    name: "Email Verify",
    path: "/emailverify",
    component: lazy(() => import("../modules/emailverifypage/Emailverifypage")),
    type: Route,
    routeType: "customer",
  },
  finalBidPayment: {
    name: "Finalbid Payment",
    path: "/bidfinalpayment",
    component: lazy(() => import("../modules/bidfinalpayment/BidFinalPayment")),
    type: Route,
    routeType: "customer",
  },

  auction: {
    name: "Auction",
    path: "/auction",
    component: lazy(() => import("../modules/auctionpage/AuctionPage")),
    props: { isAuctionPage: true },
    type: Route,
    routeType: "customer",
  },
  vendor: {
    name: "Vendor",
    path: "/vendor",
    component: lazy(() => import("../modules/auctionpage/AuctionPage")),
    props: { isAuctionPage: false },
    type: Route,
    routeType: "customer",
  },
  directSell: {
    name: "Buy Now",
    path: "/direct-sell",
    component: lazy(() => import("../modules/auctionpage/AuctionPage")),
    props: { isAuctionPage: false },
    type: Route,
    routeType: "customer",
  },
  myOrders: {
    name: "My Orders",
    path: "/my-orders",
    component: lazy(() => import("../modules/MyOrders/MyOrdersPage")),
    type: Route,
    routeType: "customer",
  },
  AllVendor: {
    name: "AllVendor",
    path: "/all-vendor",
    component: lazy(() => import("../modules/vendor/Vendor")),
    type: Route,
    routeType: "customer",
  },
  myProfile: {
    name: "My Profile",
    path: "/my-profile",
    component: lazy(() => import("../modules/myprofile/MyProfilePage")),
    type: PrivateRoute,
    routeType: "customer",
  },
  adminProfile: {
    name: "Admin Profile",
    path: "/admin-profile",
    component: lazy(() => import("../modules/myprofileadmin/MyProfileAdmin")),
    type: Route,
    routeType: "customer",
  },
  productdetails: {
    name: "Product Details",
    path: "/products-details/:id",
    component: lazy(() => import("../modules/productdetails/ProductDetails")),
    type: Route,
    routeType: "customer",
  },

  oursupport: {
    name: "Our Support",
    path: "/support",
    component: lazy(() => import("../modules/oursupport/OurSupport")),
    type: Route,
    routeType: "customer",
  },
  pageNotFound: {
    name: "Page Not Found",
    component: lazy(() => import("../components/pagenotfound")),
    type: Route,
  },
};

export const renderRoutes = Object.entries(routes);
