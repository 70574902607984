import { createStore, combineReducers } from "redux";
import { loginCache } from "./cache";
import configReducer, {
  actionSetCategories,
  actionSetModels,
  actionSetMakes,
} from "./reducer/config";
import userReducer, { actionLogin, actionLogout } from "./reducer/user";
import categorySrvc from "../service/category";
import configSrvc from "../service/config";
import { message } from "antd";
import { MSG_DURATION } from "../common/constants";

const rootReducer = combineReducers({
  userStore: userReducer,
  configStore: configReducer,
});

export const store = createStore(rootReducer);

const [user, token, vendor, customer] = loginCache.fetch();
if (user && token) {
  store.dispatch(actionLogin(user, token, vendor, customer));
}

//get all configs

categorySrvc
  .getAllCategories()
  .then(({ content }) => {
    store.dispatch(actionSetCategories(content));
  })
  .catch((err) =>
    message.error(
      "Unable to fetch Categories, please reload. Reason: " + err,
      MSG_DURATION
    )
  )
  .finally(() => { });

configSrvc
  .getAllMakes()
  .then((content) => {
    store.dispatch(actionSetMakes(content));
  })
  .catch((err) =>
    message.error(
      "Unable to fetch Makes, please reload. Reason: " + err,
      MSG_DURATION
    )
  );

// configSrvc
//   .getAllModels()
//   .then((content) => {
//     store.dispatch(actionSetModels(content));
//   })
//   .catch((err) =>
//     message.error(
//       "Unable to fetch Models, please reload. Reason: " + err,
//       MSG_DURATION
//     )
//   );

store.subscribe(() => console.log("Store updated", store.getState()));
